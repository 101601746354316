<template>
  <div class="footer">
		<div class="footer__container">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.footer{
	max-width: var(--footer-width);
	width: 100%;
	height: var(--footer-height);
	background: var(--footer-background);
	box-shadow: var(--footer-box-shadow);
	display: flex;
	align-items: center;
	border: var(--footer-border);
}
.footer__container{
	max-width: var(--footer-container-width);
	margin: auto;
	width: 100%;
}
.page--layout-sidebar{
	.footer{
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 3;
		grid-row-end: 4;
	}
}
</style>

<!-- Used in : AR, DS -->